<template>
    <div>
      <iframe :src="pdfUrl"></iframe>
    </div>
  </template>
   
  <script>
  export default {
    props: {
      pdfUrl: {
        type: String,
        required: true
      }
    }
  }
  </script>